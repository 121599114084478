body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body {
  scroll-behavior: smooth;
}

@keyframes tilt-shaking {
  0% { transform: rotate(0deg); }
  92% { transform: rotate(0deg); }
  94% { transform: rotate(2deg); }
  96% { transform: rotate(0deg); }
  98% { transform: rotate(-2deg); }
  100% { transform: rotate(0deg); }
}

#whats-bt {
  animation: tilt-shaking 2s infinite;
}